export default {
  'primary-newsletter-title': 'Sign up for my 6 part email miniseries.',
  'primary-newsletter-byline':
    'Stay in the loop for the super rad stuff I have planned for the future!',
  'primary-newsletter-button-cta-label': 'Sign up',
  'quiz-answer-neutral-default': `Thanks for submitting your answer!\n\nI'll send the next lesson in 5-10 minutes. Check your inbox.\n\n_Thanks,_\n_Cassidy_`,
  'quiz-answer-neutral-last': `Thanks for submitting your answer!\n\nThis was the last question from the Keyboard Legend email series. We hope you learned something new, and we look forward to sharing more in the future!\n\n_Thanks,_\n\n_Cassidy_`,
  'quiz-answer-correct-default': `Nice work. You chose the correct answer!\n\nI'll send the next lesson in 5-10 minutes. Check your inbox.\n\n_Thanks,_\n\n_Cassidy_`,
  'quiz-answer-correct-last': `Nice work. You chose the correct answer!\n\nThis was the last lesson from the Keyboard Legend email series. We hope you learned something new, and I look forward to sharing more in the future!\n\n_Thanks,_\n\n_Cassidy_`,
  'quiz-answer-incorrect-default': `You chose an incorrect answer, but don't worry. Just go back and re-read the email and check out any linked resources. You can refresh the page if you'd like to try again! 👍\n\nI'll send the next email in 5-10 minutes too so you can learn more.\n\n_Thanks,_\n\n_Cassidy_`,
  'quiz-answer-incorrect-last': `You chose an incorrect answer, but don't worry. Just go back and re-read the email and check out any linked resources. You can refresh the page if you'd like to try again! 👍\n\nThis was the last lesson from the Keyboard Legend email course. We hope you learned something new, and I look forward to sharing more in the future!\n\n_Thanks,_\n\n_Cassidy_`,
}
